<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <svg
        v-if="item && item.icon && item.icon === 'CommerciaIcon'"
        width="164"
        height="167"
        viewBox="0 0 164 167"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M57.3685 22.4552C25.5813 34.2307 6.36192 66.5771 24.2302 109.602C46.9754 164.368 124.488 163.335 139.54 102.302C129.279 124.307 82.4424 140.634 56.5606 97.8345C38.2321 67.5262 40.9559 40.0413 58.9053 30.8532C76.7832 21.7022 89.3663 49.4808 94.3485 66.082L131.725 34.7906C119.022 21.9403 76.873 15.3561 57.3685 22.4552Z"
          fill="#B2D632"
        />
      </svg>
      <feather-icon
        v-else
        :icon="item.icon || 'CircleIcon'"
      />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<style>
  .text-truncate {
    white-space: pre-wrap;
  }
</style>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
