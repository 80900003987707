<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      © Hafidme {{ new Date().getFullYear() }}
      <span class="d-none d-sm-inline-block">, Todos os direitos reservados</span>
    </span>
    <span class="float-md-right"><small>Versão {{ version }}</small></span>
  </p>
</template>

<script>

export default {
  computed: {
    version() {
      let env = ''

      if (process.env.NODE_ENV !== 'production') {
        env = `${process.env.NODE_ENV.substring(0, 3)}-`
      }

      return `${env}0.${process.env.VUE_APP_COMMIT_COUNT} (${process.env.VUE_APP_COMMIT_HASH})`
    },
  },
}
</script>
